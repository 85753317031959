import React, { useCallback, useState } from "react";
import { Alert, Badge, Button } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
// import { useNavigate } from "react-router-dom";
import { useOrgInfo } from "../context/orgContext";
import { formatCurrency } from "../utils/currencyFormat";
import useAdminOrgId from "../hooks/useAdminOrgId";
import notify from "../utils/notify";
import { collection, doc, getDoc, getDocs, onSnapshot, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { usePaystackPayment } from "react-paystack";
import { useUser } from "../context/UserContext";
import { sendSMS } from "../services/core-api";
import { formatDate } from "../utils/formatDate";
import { useConfig } from "../context/keyContext";

// Enum for the plan types
enum PlanType {
  FREE_TRIAL = "Free trial",
  PAY_AS_YOU_GO = "Blaze plan",
}

const Subscription = () => {
  const isDev = process.env.NODE_ENV === "development";
  const { plan, companyNumber } = useOrgInfo();
  const organizationId = useAdminOrgId();
  const {user} = useUser()
  const {config} = useConfig()

  const plans = [
    {
      plan: PlanType.FREE_TRIAL,
      price: "0.0",
      period: "/month",
      features: [
    
        "Free 20 SMS unit, then purchase extra SMS on demand",
        "Unlimited Users",
        "Unlimited Administrators",
        "Unlimited Collaborators",
        "Onboard up to 20 customers",
        "Record 50 transactions per day",
      ],
    },
    {
      plan: PlanType.PAY_AS_YOU_GO,
      desc: "Pay as you go plan",
      period: "/month",
      features: [
        "Unlimited Users",
        "Unlimited Administrators",
        "Unlimited Collaborators",
        "Generate Customer Statement",
        "SMS unit - Purchase extra SMS on demand plus 10 free sms units on every purchase",
        // "SMS unit - Free 100 SMS units/month, then buy extra SMS on demand",
        // "Users - No cost up to 5 users/month, then GHS0.10/user",
        // "SMS unit - No cost up to 500 SMS units/month, then buy extra SMS on demand",
        // "Administrators - No cost up to 2 Administrators/month, then GHS0.15/user",
        "Customers - No cost up to 20 customers, then GHS0.10/customer", 
        "Transactions - No cost up to 50 transactions per day, then GHS0.10/transaction",
      ],
    },
  ];

  const handleSubscription = async (planType: PlanType) => {  
    if (!organizationId) {
      notify("Oops! Something happened, please try again");
      return;
    }
    try {
      const orgQuery = query(
        collection(db, "orgInfo"),
        where("orgId", "==", organizationId)
      );
  
      const querySnapshot = await getDocs(orgQuery);
  
      if (querySnapshot.empty) {
        notify("Oops! Something happened, please try again");
        return;
      }
  
      const orgDoc = querySnapshot.docs[0];
      const orgDocRef = orgDoc.ref;
  
      const existingPlan = orgDoc.data().plan;

      if (planType === PlanType.FREE_TRIAL) {
        await updateDoc(orgDocRef, {
          plan: {
            ...existingPlan,
            payAsYouGo: false,
          },
        });
      } else if (planType === PlanType.PAY_AS_YOU_GO) {
        await updateDoc(orgDocRef, {
          plan: {
            ...existingPlan,
            createdAt: serverTimestamp(),
            payAsYouGo: true,
          },
        });
      }
  
      notify(`Your subscription has been modified to ${planType}.`);
    } catch (err: any) {
      notify("Failed to update the subscription. Please try again.");
    }
  };


const payConfig = (amount: number, email: string, key: string) => {
  const reference = (new Date()).getTime().toString();
  return  {
    reference,
    email:  email,
    amount: amount * 100, 
    currency: 'GHS',
    channels: ["mobile_money"],
    publicKey: key || '',
    metadata: {
      custom_fields: [
          {
              display_name: 'description',
              variable_name: 'description',
              value: 'Subscription'
          }
      ]
  }
  }
}

  const initializePayment = usePaystackPayment(payConfig(plan.monthlyChargeDue, user?.email || '', config?.payStackKey || ''));

  const paySubscriptionDue = () => {
    initializePayment({
      onSuccess,
      onClose,
      config: payConfig(plan.monthlyChargeDue, user?.email || '', config?.payStackKey || '')
    });
  };
  
  const clearDueBalanceAfterSuccessPayment = () => {
    if (!organizationId) return;
  
    try {
      const q = query(collection(db, "orgInfo"), where("orgId", "==", organizationId));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref; 
          const orgData = querySnapshot.docs[0].data().plan;

          if (orgData?.monthlyChargeDue > 0) {
            updateDoc(docRef, {
              plan:{ 
                ...orgData,
                monthlyChargeDue: 0
               }
            }
            )
              .then(() => {
                notify('Payment successful')
              })
              .catch((error) => {
                console.error(error);
              });
          }
        } else {
          console.warn("No organization found for the given orgId");
        }
      });

      return unsubscribe;
    } catch (error: any) {
      console.error("Error setting up real-time listener for orgInfo:", error);
      alert("Error setting up real-time updates for org info");
    }
  };

  

  const onSuccess = useCallback((reference: any) => {
    if(reference){
      clearDueBalanceAfterSuccessPayment()
      sendSMS('Pay as you go monthly due payment successful', [companyNumber])
    }
}, [clearDueBalanceAfterSuccessPayment])



const onClose = () => {
 notify('Payment window closed')
}


  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
    <Alert icon={<IconInfoCircle />} color="#c9b68b" mt={10}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p style={{ fontWeight: "bold", fontSize: "16px", margin: "5px 0" }}>
              You are currently on a{" "}
              <span>{plan.payAsYouGo ? "Pay as you go" : "Free"} plan</span>
            </p>
            <p style={{ fontSize: "14px", color: "#666" }}>
              Your usage will be tracked and billed at the end of the month.
            </p>
            <Badge color={plan.payAsYouGo ? "green" : "orange"} mt={10}>
              {plan.payAsYouGo ? "Pay As you go" : "Free plan"}
            </Badge>

            {plan.payAsYouGo && <Badge ml={10} color={plan.planExpired ? "red" : "green"} mt={10}>
             <span style={{ fontWeight: 'bolder', color: '#000', marginRight: 5}}>Status: </span> {plan.planExpired ? "Inactive" : "Active"}
            </Badge>}

            {plan.payAsYouGo &&   <p style={{ fontWeight: "bold", fontSize: "14px", margin: "5px 0", marginTop: 20 }}>
              Subscription date:{" "} 
              <span>{formatDate(plan?.createdAt)}</span>
              </p>}
          
          </div>
          <div>
            <p style={{ fontSize: "16px", fontWeight: "bold", margin: "10px 0" }}>
              Month Balance:
            </p>
            <p style={{ fontSize: "24px", fontWeight: "bold", color: "#28a745" }}>
              {formatCurrency(plan.monthlyChargeDue, "GHS")}
            </p>
            <p style={{ fontSize: "14px", color: "#666" }}>
             Current usage total amount to pay for this month.
            </p>
            <Button
             onClick={()=>  paySubscriptionDue()}
              disabled={plan.monthlyChargeDue <= 0}
              color="#000"
              variant="filled"
              mt={10}
              size="xs"
            >
              Pay now
            </Button>
          </div>
        </div>
      </Alert> 
      

   
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "20px" }}>
          {plans.map((subPlan) => (
            <div
              key={subPlan.plan}
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "15px",
                width: "30%",
                textAlign: "center",
                cursor: "pointer",
                margin: 10,
                backgroundColor: "#f0f0f0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h2 style={{ fontWeight: "bold", fontSize: 18, marginBottom: 10 }}>
                  {subPlan.plan}
                </h2>
                {subPlan.desc && <small>{subPlan.desc}</small>}
                {subPlan.price && (
                  <p style={{ fontSize: "30px", fontWeight: "bold", margin: "10px 0" }}>
                    {formatCurrency(parseFloat(subPlan.price), "GHS")}{" "}
                    <span style={{ fontSize: "14px", fontWeight: "normal" }}>
                      {subPlan.period}
                    </span>
                  </p>
                )}
                <ul style={{ listStyleType: "none", padding: "0", textAlign: "left" }}>
                  {subPlan.features.map((feature, index) => (
                    <li key={index} style={{ margin: "5px 0", display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: 10, color: "green" }}>✔️</span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>

              <Button
                onClick={() => handleSubscription(subPlan.plan)}
                disabled={ 
                  plan.planExpired ||
                  (subPlan.plan === PlanType.PAY_AS_YOU_GO && plan.payAsYouGo) || 
                  (subPlan.plan === PlanType.FREE_TRIAL && !plan.payAsYouGo)
                }
                color="#000"
                variant="filled"
                mt={10}
              >
                {subPlan.plan === PlanType.PAY_AS_YOU_GO && plan.payAsYouGo
                  ? "Current Plan"
                  : subPlan.plan === PlanType.FREE_TRIAL && !plan.payAsYouGo
                  ? "Current Plan"
                  : "Subscribe"}
              </Button>
            </div>
          ))}
        </div>
  
    </div>
  );
};

export default Subscription;
