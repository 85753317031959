import React, { createContext, useState, useEffect, useContext } from 'react';// Make sure this is your Firebase auth setup
import { auth } from '../firebase';
import { getConfig } from '../services/core-api';


interface ConfigData {
    payStackKey: string
    encryptKey: string
  }
interface ConfigContextType {
    config: ConfigData | null;
    loading: boolean;
    error: string | null;
  }

  
const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

export const ConfigProvider = ({ children }: any) => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const data = await getConfig();
        setConfig(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ config, loading, error }}>
      {children}
    </ConfigContext.Provider>
  );
};

// Custom hook to use the config
export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
};
