import React, { useEffect, useState } from 'react'
import { db, auth } from '../firebase'
import { doc, getDocs, addDoc, setDoc, collection, query, where, orderBy } from "firebase/firestore";
import { Button, Loader, Paper, Table, Text, TextInput, Title } from '@mantine/core';
import { getDomainFromEmail } from '../utils/getUserDomain';
import InputText from '../components/InputText';
import { formatCurrency } from '../utils/currencyFormat';
import { IconFileExcel, IconFilter, IconInfoCircle, IconRefresh, IconRotate } from '@tabler/icons-react';
import { handleExport } from '../services/core-api';
import { useTransactionDataContext } from '../context/TransactionsContext';
import TransactionsTable from '../components/TransactionsTable';


export default function Withdrawals() {
  const [search, setSearch] = useState('');
  const [isloading, setIsloading]= useState(false)
  const { withdrawalTransactions, setWTransactions} = useTransactionDataContext()
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
const [transactions, setTransactions] = useState(withdrawalTransactions)



  interface Transaction {
    id: string;
    customer: string;
    amount: string;
    accountNumber: string;
    date: string;
    agentName: string;
    agentID: string;
    transactionsMethod: string;
    agentEmail: string;
    phone: string;
    createdAt: any;
    approved: boolean;
    transactionType: string;
  }

  const filterData = () => {
    let filtered = transactions;
 
    if (search) {
      filtered = filtered.filter(trans =>
        trans.accountNumber.toLowerCase().includes(search.toLowerCase())
      );
    }
  
  
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);       
      filtered = filtered.filter(trans => {
        const transactionDate = new Date(trans.createdAt.seconds * 1000);
        return transactionDate >= start && transactionDate <= end;
      });
    }
    

    setTransactions(filtered);
  };
  
    
      // const filterData = ()=> {
      //  const filtered =  withdrawalTransactions?.filter(trans => trans.customer.includes(search))
      //  setWTransactions(filtered)
      // }
      const downloadData = ()=> {
        setIsloading(true)
        handleExport(withdrawalTransactions? withdrawalTransactions : []).then(()=> {
        setIsloading(false)
        }).catch((err)=> {
          console.log(err)
        setIsloading(false)
        })
      }

        // Calculate the total amount of withdrawal balances
     const totalWithdrawalAmount = transactions.reduce((sum: number, trans: Transaction) => sum + parseFloat(trans.amount), 0);

  return (
    <>
        <Paper
      withBorder
      style={{borderColor: '#dedede'}}
      p={20}
      >
        <div  style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: 'wrap',
        }}>
           
    
         <div>
         <Title mb={5} order={5} style={{ fontWeight: 'normaL'}}> Total Withdrawal Balance</Title>
         <Title mb={10} order={2}> {formatCurrency(totalWithdrawalAmount, 'GHS')}</Title>
         {/* <small>Total withdrawal balance</small> */}
         </div>


         <div>
         <Title mb={5} order={5} style={{ fontWeight: 'normaL'}}> Total Withdrawals</Title>
         <Title mb={10} order={2}> {transactions.length}</Title>

         </div>

         {/* <div>
         <small style={{ color: 'orangered', display: 'flex', alignItems: 'center'}}><IconInfoCircle style={{ marginRight: 5}} /> Pending withdrawals are added to total balance if any, but will be removed if not approved</small>
         </div> */}

        </div>
     

     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
    <div style={{ display: 'flex', width: '70%', flexWrap: 'wrap', alignItems:'center'}}>
    <Button loading={isloading}  variant="outline" disabled={isloading} mr={10} color="#274941" onClick={downloadData}> 
        <IconFileExcel /> &nbsp; Export to XLSx
        </Button>
        
      
        <TextInput
         mr={10}
         name='State date'
          width='20%'
          type='date'
          value={startDate}
          onChange={(e:any) => setStartDate(e.target.value)}
          placeholder='Start Date'
        />
          <Text fw={'bold'}>To</Text>
        <TextInput
        ml={10}
         name='End date'
         width='20%'
          type='date'
          value={endDate}
          onChange={(e: any) => setEndDate(e.target.value)}
          placeholder='End Date'
        />

<Button leftSection={<IconFilter size={20}/>} variant='filled' color='#000' onClick={filterData} style={{ marginInline: 10 }}>
          Filter Date
        </Button>
        </div>

     
        <Button leftSection={<IconRotate size={20}/>} variant='filled' color='#000' onClick={()=> {
          setStartDate('')
          setEndDate('')
          setTransactions(withdrawalTransactions)
        }} style={{ marginInline: 10 }}>
          Refresh
        </Button>

       {/* <Button disabled variant="filled" color="#274941" onClick={()=> {}}> Withdrawal</Button> */}
      </div>

      <div className='tableOnMobile'>
    <TransactionsTable showPageTotalAmount={true} data={transactions} dataType='withdrawal'/>
    </div>
    </Paper>
    </>
  )
}


