import React, { useState, useEffect, ReactEventHandler } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes/const";
import InputText from "../components/InputText";
import { Button, Loader, Modal, Select, Space, Text, TextInput, Title } from "@mantine/core";
import '../styles/auth.css'
import { IconEye, IconEyeClosed, IconLock, IconMail, IconPassword } from "@tabler/icons-react";
import SignUp from "./signUp";
import { useUser } from "../context/UserContext";
import AppLoader from "../components/loader";
import notify from "../utils/notify";
import Logo from '../assets/applogo-light.svg'

export default function Authentication() {
  const [loginScreen, setLoginScreen] = useState<boolean>(true)
  const [SignUpState, setSignUpState] = useState<boolean>(false)
  const isProd = process.env.NODE_ENV === 'production'
  const { loading } = useUser()
  const [resetPass, setResetPass] = useState<boolean>(false)


 const renderContent = ()=> {
   switch (true) {
    case loginScreen:
      return <LoginScreen />
    case SignUpState:
      return <SignUp />
    case resetPass:
      return <ResetPass />
   }
 }

  return (
  <>
  {loading ? <AppLoader /> : 

    <div className="authContainer">
    <div className="auth">
     <div style={{ display: 'flex', justifyContent:'space-around', width: '100%'}}>
      </div>
      {renderContent()}
      {loginScreen &&  <Text mt={30}>Forgot password?  <span onClick={()=> {
        setResetPass(true)
        setLoginScreen(false)
      }} style={{ cursor: 'pointer', fontWeight: 'bolder'}}>Reset</span></Text>  }
      
      {loginScreen ?  
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', padding: 0,  marginBottom: 50}}>
    <Text>Don't have an account? <span onClick={()=> {
      setSignUpState(true)
       setLoginScreen(false)
    }} style={{ fontWeight: 'bolder', cursor: 'pointer'}}>Create one</span></Text>
    </div> :  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', }}>
    <Text mt={20}>Already have an account? <span onClick={()=> {
      setSignUpState(false)
      setLoginScreen(true)
    }} style={{ fontWeight: 'bolder', cursor: 'pointer'}}>Log in</span></Text>
    </div>}
     </div>
    </div>
    }
  </>
  );
}


function ResetPass (){
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = () => {
    setIsLoading(true)
    sendPasswordResetEmail(auth, email)
      .then(() => {
        notify('Email send. Please check your email to reset your password!')
    setIsLoading(false)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // console.error(errorCode, errorMessage);
        alert(errorMessage);
        setIsLoading(false)
        // Handle errors here, e.g., invalid email, etc.
      });
  };


  return (
    <div className="formCard">
    <div style={{ marginBottom: 50}}>
    <Title mb={20} order={1} className="loginIntro">Reset Password </Title>
   <Text className="loginSubText">
    Link to reset password will be sent to your email</Text>
    </div>

       <InputText
       iconLeft={<IconMail />}
       width="100%"
         value={email}
         name="email"
         height={45}
         onChange={(e: any)=> setEmail(e.target.value)}
         placeholder="Email"
         type="text"
       />
       <Space h={10} />

       <Button disabled={isLoading} h={45} style={{ fontSize: 16}} variant="filled" mt={10} color="#000" w={"100%"} onClick={resetPassword} className="primaryBtn">
         {isLoading? <Loader size={20} color="#000" /> : "Reset password"}
       </Button>
     </div>
  )
}


function LoginScreen (){
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  
  const handleOnchange = (event: any) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

 const handleSignInUser = async () => {
  if(form.email === '' || form.password === ''){
    return notify('Field can not be empty')
  }
  setIsLoading(true);
  await signInWithEmailAndPassword(auth, form.email.trim(), form.password.trim())
    .then((user) => {
      if(user.user.emailVerified === true){
        navigate(ROUTES.home)
        setIsLoading(false);
      }else{      
        navigate(ROUTES.verifyEmail)
        setIsLoading(false);
      }
    })
    .catch((err: any) => {
      setIsLoading(false);
      if(err.message === 'Firebase: Error (auth/network-request-failed).'){
        return notify('Error: Please check your network and try again', 'error')
      }

      if(err.message === 'Firebase: Error (auth/invalid-email).'){
       return notify('Account does not exist',  'error')
      }
      else if(err.message === 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).'){
     return notify("Account temporarily disabled due to many failed login attempt. try again later",  'error')
      }
     else if(err.message === 'Firebase: Error (auth/invalid-credential).'){
      return notify('Invalid credentials', 'error')

      }
      else if(err.message === 'Firebase: Error (auth/user-disabled).' ){
       return notify('Account disabled. Check back later',  'error')
      }
      else{
  notify(err.message,  'error')
      }
    

     
    });
};
 


const [showPassword, setShowPassword]= useState(false)

  return (
    <>
   <div className="formCard">

     <img src={Logo} width={150} style={{ marginBottom: 30}} />
    {/* <Text mb={40} mt={20} className="loginSubText">
    Log in to your account to access all the tools you need to streamline your operations</Text> */}


        <InputText
        iconLeft={<IconMail />}
        width="100%"
          value={form.email}
          name="email"
          height={45}
          onChange={handleOnchange}
          placeholder="Email"
          type="text"
        />
        <Space h={10} />
        <InputText
         iconLeft={<IconLock />}
           width="100%"
           height={45}
          name="password"
          value={form.password}
          onChange={handleOnchange}
          placeholder="Password"
          type= {showPassword ? 'text': 'password'}
          icon={showPassword ? <IconEye style={{ cursor: 'pointer'}} onClick={()=> setShowPassword(!showPassword)}/> : <IconEyeClosed style={{ cursor: 'pointer'}} onClick={()=> setShowPassword(!showPassword)}/>}
        />
        <Space h={10} />

        <Button disabled={isLoading} h={45} style={{ fontSize: 16}} variant="filled" mt={10} color="#000" w={"100%"} onClick={handleSignInUser} className="primaryBtn">
          {isLoading? <Loader size={20} color="#000" /> : "Log In"}
        </Button>
      </div>
    
    </>
  )
}





