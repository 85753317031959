import React, { useEffect, useState } from "react";
import { db, auth } from "../firebase";
import { Avatar, Badge, em, Loader, Paper, Select, Table, TextInput, Title } from "@mantine/core";
import { createUser, disableUser, enableUser, getAgents, updateUser } from "../services/core-api";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import InputText from "../components/InputText";
import {
  IconPencil,
  IconRefresh,
  IconTrash
} from "@tabler/icons-react";
import {ApproveModal, DeleteModal} from "../components/DeleteModal";
import useAdminOrgId from "../hooks/useAdminOrgId";
import { getDomainFromEmail } from "../utils/getUserDomain";
import notify from "../utils/notify";
import { useAgentsContext } from "../context/agentsContext";
import { useAdminStatusContext } from "../context/adminContext";
import { nanoid } from "nanoid";

interface customClaimsProp{
  admin: string
  agentId: string
  orgId: string
  role: string
}

export interface userType {
  displayName: string;
  email: string;
  id: string;
  phoneNumber: string,
  role: string
  customClaims: customClaimsProp
  orgId: string
  emailVerified: boolean,
  photoURL: string
  disabled: boolean
}

export default function Agents() {
  const collectionID = "users";
  const [search, setSearch] = useState("");
  const [isloading, setIsloading] = useState(false);
  const userEmail = auth.currentUser?.email || "";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(nanoid(6));
  const [role, setRole] = useState<string | null>('');
  const [displayName, setDisplayName] = useState("");
  const [number, setNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<string>('');
  const [userToDelete, setUserToDelete] = useState('')
  const [userToEnable, setUserToEnable] = useState('')
  const [editUser, setEditUser] = useState(false)
  const [processDelete, setProcessDelete] = useState(false)
  const [processEnable, setProcessEnable] = useState(false)
  const { agents, setAgents, numberOfAdmins, loading, refresh } = useAgentsContext()
  const { isAdmin } = useAdminStatusContext()

  const rows = agents?.map((agent) => (
    <Table.Tr key={agent.id}>
      <Table.Td>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar style={{ marginRight: 10 }} src={agent.photoURL} /> {agent.email === auth.currentUser?.email && <Badge color="green" variant="light">You</Badge>}
        </div>
      </Table.Td>
      <Table.Td>{agent.customClaims?.agentId}</Table.Td>
      <Table.Td>{agent.displayName}</Table.Td>
      <Table.Td>{agent.email}</Table.Td>
      <Table.Td>{agent.phoneNumber}</Table.Td>
      <Table.Td><Badge variant="outline" color={agent.customClaims?.role === 'Administrator' ? 'green' : agent.customClaims?.role === 'Collaborator' ? 'orange' : 'blue'}>{agent.customClaims?.role}</Badge></Table.Td>
      <Table.Td>{agent.emailVerified ? <Badge color="green">verified</Badge> : <Badge color="red">unverified</Badge>}</Table.Td>
      <Table.Td>{agent.disabled ? <Badge color="red">Disabled</Badge> : <Badge color="green">Active</Badge>}</Table.Td>

      <Table.Td>
        <>
          {auth.currentUser?.email === agent.email ? '' :
            <div style={{ visibility: !isAdmin ? 'hidden' : 'visible', display: 'flex', alignItems: 'center' }}>
              <Button leftSection={<IconPencil color="#000" size={22} />} variant="default" mr={10} size="xs" disabled={agent.disabled} onClick={() => handleEditClick(agent)}>
                Edit
              </Button>
              <Button size="xs" color="green" mr={10} disabled={!agent.disabled ? true : false} onClick={() => openApproveModal(agent.email)}>Enable</Button>
              <Button color="red" size="xs" disabled={agent.disabled} onClick={() => openDeleteModal(agent.email)}>Disable</Button>
            </div>
          }
        </>
      </Table.Td>
    </Table.Tr>
  ));

  const [opened, { open, close }] = useDisclosure(false);
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [enableOpen, setEnableOpen] = useState(false)

  const organizationId = useAdminOrgId()

  const openDeleteModal = (email: any) => {
    setDeleteOpen(true)
    setUserToDelete(email)
  }

  const openApproveModal = (email: string) => {
    setEnableOpen(true)
    setUserToEnable(email)
  }

  const disableStaff = async (staff: any) => {
    setProcessDelete(true)
    try {
      const response = await disableUser(staff)
      notify(response)
      refresh()
      setDeleteOpen(false)
      setProcessDelete(false)
    } catch (err) {
      setProcessDelete(false)
    }
  }

  const enableStaff = async (staff: string) => {
    setProcessEnable(true)
    try {
      const response = await enableUser(staff)
      notify(response)
      refresh()
      setEnableOpen(false)
      setProcessEnable(false)
    } catch (err) {
      setProcessEnable(false)
    }
  }

  useEffect(() => {
    if (number) {
      setFormattedPhoneNumber(`+233${number}`);
    } else {
      setFormattedPhoneNumber('');
    }
  }, [number]);

  function openAgentModal() {
    setEditUser(false)
    setEmail('');
    setDisplayName('');
    setNumber('');
    setRole('');
    open();
  }

  const createAgent = () => {
    if(email === '' || displayName === '' || formattedPhoneNumber === '' || role === ''){
      return notify('Provide required fields')
    }
    if(formattedPhoneNumber.length < 10){
      return notify('Invalid phone number')
    }
    if (!email.includes('@') || !email.includes('.') || email.indexOf('@') > email.lastIndexOf('.')) {
      notify('Invalid email');
      return;
  }

    const generateAgentID = () => {
      return `DC${Math.floor(Math.random() * 10000).toString().padStart(4, '0')}`;
    };

    const User = {
      email: email.trim(),
      password: password.trim(),
      displayName: displayName,
      phoneNumber: formattedPhoneNumber,
      role: role,
      agentID: generateAgentID(),
      orgId: organizationId,
    };

    if (role === 'Administrator' && numberOfAdmins >= 2) {
      notify("You have exceeded the number of admins to create")
      return
    }
    setIsloading(true)
    createUser(User)
      .then((res) => {
        setIsloading(false)
        notify(res)
        close()
        refresh()
      })
      .catch((err: any) => {
        notify(err.message || err.code)
        setIsloading(false)
        notify('Oops! something happened please try again')
        close()
      }).finally(() => setIsloading(false));
  };

  const filterData = () => {
    const filtered = agents?.filter((agent) =>
      agent.displayName.includes(search)
    );
    setAgents(filtered);
  };

  const handleEditClick = async(agent: userType) => {
    setEditUser(true)
    setEmail(agent.email);
    setDisplayName(agent.displayName);
    setNumber(agent.phoneNumber.replace('+233', ''));
    setRole(agent.customClaims?.role);
    open(); 
  };

  const updateUserAccount = async() => {
    try{
      setIsloading(true)
      const response = await updateUser({
        email, displayName, role, phoneNumber: formattedPhoneNumber
      })
      refresh()
      close()
      notify(response.message)
    }catch(err) {
      close()
     return err
    }finally{
      setIsloading(false)
    }
  }

  useEffect(() => {
    console.log(role)
  })

  return (
    <div>
      <ApproveModal onClose={() => setEnableOpen(false)} opened={enableOpen} content="Are you sure you want to enable this user" title={<Title order={5}>Enable User</Title>} isLoading={processEnable} proceed={() => enableStaff(userToEnable)} />

      <DeleteModal title={<Title order={5}>Disable User</Title>} isLoading={processDelete} proceed={() => disableStaff(userToDelete)} onClose={() => setDeleteOpen(false)} opened={deleteOpen} content="Are you sure you want to disable this user" />

      <Modal
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        opened={opened}
        onClose={close}
        title={<Title order={5} style={{ padding: 10 }}>
          {editUser ? 'Update User Account' : 'Create User Account'}
           </Title>}
        centered
      >
        <div style={{ padding: 10 }}>

          <TextInput
            disabled={editUser}
            label="Email"
            mb={10}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
          />
          <TextInput
            label="Full Name"
            mb={10}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Full Name"
          />
          <TextInput
            label="Phone Number"
            mb={10}
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            placeholder="Phone number"
          />

          <Select
            label="Role"
            placeholder="Select Role"
            data={['Administrator', 'Staff', 'Collaborator']}
            value={role} onChange={setRole}
          />
         
         {editUser ?   <Button
           loading={isloading}
            disabled={!isAdmin ? true : false}
            w={"100%"}
            variant="filled"
            mt={20}
            color="#000"
            onClick={() => updateUserAccount()}
          >
            Update user
          </Button>
          :
          <Button
          disabled={!isAdmin ? true : false}
          loading={isloading}
          w={"100%"}
          variant="filled"
          mt={20}
          color="#000"
          onClick={() => createAgent()}
        >
          Create account
        </Button>
          }
       
        </div>
      </Modal>

      <Paper p={20}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Title order={5}> Employees </Title>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <InputText
            width="30%"
            value={search}
            type="text"
            name="search"
            onChange={(e: any) => setSearch(e.target.value)}
            placeholder="Search by name"
          />
          <Button
            className="removeOnMobile"
            variant="default"
            color="#274941"
            onClick={filterData}
            style={{ marginInline: 10 }}
          >
            {" "}
            Filter by user
          </Button>
          <Button disabled={!isAdmin ? true : false} variant="filled" color="#000" onClick={openAgentModal}>
            {" "}
            Create user
          </Button>
          <Button ml={10} leftSection={loading ? <Loader size={16} color="#000" /> : <IconRefresh size={16} />} disabled={loading ? true : false} variant="filled" color="#000" onClick={refresh}>
            {" "}
            Refresh
          </Button>

        </div>

        <div className="tableOnMobile">
          <Table highlightOnHover stickyHeader stripedColor='#f2f2f2' highlightOnHoverColor='#f4f3ef' stickyHeaderOffset={60} striped mt={60}>
            <Table.Thead style={{ backgroundColor: '#fafafa' }}>
              <Table.Tr>
                <Table.Th style={{ fontWeight: 'bold' }}>Profile</Table.Th>
                <Table.Th style={{ fontWeight: 'bold' }}>Staff ID</Table.Th>
                <Table.Th style={{ fontWeight: 'bold' }}> Name</Table.Th>
                <Table.Th style={{ fontWeight: 'bold' }}>Email</Table.Th>
                <Table.Th style={{ fontWeight: 'bold' }}>Phone Number</Table.Th>
                <Table.Th style={{ fontWeight: 'bold' }}>Role</Table.Th>
                <Table.Th style={{ fontWeight: 'bold' }}>Verification</Table.Th>
                <Table.Th style={{ fontWeight: 'bold' }}>Status</Table.Th>
                <Table.Th style={{ fontWeight: 'bold' }}>Action</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </div>

      </Paper>
    </div>
  );
}
