import LandingLayout from "../layouts/landing";
import LandingHero from "../components/LandingHero";
import marketWoman from "../assets/marketWoman.png";
import manOnPhone from "../assets/manOnPhone.svg";
import {
  IconBrandSpeedtest,
  IconBrandTelegram,
  IconClock,
  IconDeviceDesktopAnalytics,
  IconDeviceMobile,
  IconLock,
  IconLockAccess,
  IconRocket,
  IconSeeding,
  IconTrack,
  IconUsers,
} from "@tabler/icons-react";
import LandingNav from "../components/LandingNav";

const LandingHome = () => {
  return (
    <LandingLayout>
      <>
        <LandingNav inView={true} />
        <LandingHero />

        {/* Who Can Use DigiCollect Section */}
        <section className="py-20 bg-gray-100">
          <article className="container mx-auto py-20 px-3">
            <h2 className="text-4xl font-bold text-center mb-10 Hero text-black">
              Who Is DigiCollect For?
            </h2>
            <p className="text-lg text-gray-600 text-center mb-10">
              DigiCollect is designed for a range of users in the financial
              sector to enhance their operations and streamline processes.
            </p>
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <li className="p-6 bg-white rounded-lg shadow-md text-center transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-xl">
                <IconDeviceDesktopAnalytics
                  size={48}
                  className="text-black-500 mb-4"
                />
                <h3 className="text-2xl font-semibold mb-3 Hero text-black">
                  Microfinance Institutions
                </h3>
                <p className="text-gray-500">
                  Streamline operations, record savings, and manage customer
                  transactions efficiently.
                </p>
              </li>
              <li className="p-6 bg-white rounded-lg shadow-md text-center transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-xl">
                <IconTrack size={48} className="text-black-500 mb-4" />
                <h3 className="text-2xl font-semibold mb-3 Hero text-black">
                  Savings and Loans Companies
                </h3>
                <p className="text-gray-500">
                 Streamline customer savings and loans seamlessly, providing
                better financial oversight and growth opportunities.
                </p>
              </li>
              {/* <li className="p-6 bg-white rounded-lg shadow-md text-center transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-xl">
                <IconHeartRateMonitor
                  size={48}
                  className="text-black-500 mb-4"
                />
                <h3 className="text-2xl font-semibold mb-3 Hero text-black">
                  Field Agents
                </h3>
                <p className="text-gray-500">
                  Who collect deposits and withdrawals on behalf of
                  organizations, using the DigiCollect mobile app for real-time
                  updates.
                </p>
              </li> */}
              <li className="p-6 bg-white rounded-lg shadow-md text-center transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-xl">
                <IconSeeding size={48} className="text-black-500 mb-4" />
                <h3 className="text-2xl font-semibold mb-3 Hero text-black">
                  Susu (Deposit-taking) Businesses
                </h3>
                <p className="text-gray-500">
                Track deposits and withdrawals while ensuring secure and
                reliable transaction records.
                </p>
              </li>
            </ul>
          </article>
        </section>

        {/* benefits section */}
        <section className="py-32 bg-black">
          <div className="container mx-auto px-3">
            {" "}
            <h4 className=" text-white mb-8 text-4xl font-bold text-center Hero">
              Why choose DigiCollect
            </h4>
            <p className="text-white text-center mb-16 max-w-3xl mx-auto leading-relaxed text-lg">
              DigiCollect is designed to serve unbanked Africans by providing a
              secure and convenient platform for collecting and managing
              savings.
            </p>
            <article className="container mx-auto flex flex-col lg:flex-row px-3 gap-x-8 items-center">
              <div className="w-full lg:w-6/12 mb-20">
                <div className="flex flex-col items-center lg:items-start">
                  <div className="lg:w-10/12 mb-8">
                    <img
                      src={marketWoman}
                      alt="Market Woman"
                      loading="lazy"
                      className="rounded-lg shadow-xl"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-6/12">
                <ul className="text-gray-500">
                  <li className="grid mb-10 items-start grid-flow-col justify-start">
                    <div className="w-12 h-12 bg-yellow-500 text-white rounded-full flex items-center justify-center mr-5">
                      <IconBrandTelegram color="#000" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-yellow-500">
                        Increased Efficiency
                      </h3>
                      <p className="text-md text-white">
                        Our mobile app allows field agents to record
                        transactions in real-time, eliminating the delay and
                        potential errors associated with manual data entry.
                      </p>
                    </div>
                  </li>

                  <li className="grid mb-10 items-start grid-flow-col justify-start">
                    <div className="w-12 h-12 bg-yellow-500 text-white rounded-full flex items-center justify-center mr-5">
                      <IconDeviceDesktopAnalytics color="#000" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-yellow-500">
                        Monitoring Dashboard
                      </h3>
                      <p className="text-md text-white">
                        Our intuitive admin dashboard tracks and manages client
                        savings and loan activities.
                      </p>
                    </div>
                  </li>

                  <li className="grid mb-10 items-start grid-flow-col justify-start">
                    <div className="w-12 h-12 bg-yellow-500 text-white rounded-full flex items-center justify-center mr-5">
                      <IconUsers color="#000" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-yellow-500">
                       Customer Satisfaction
                      </h3>
                      <p className="text-md text-white">
                     Provide a seamless experience that keeps customers satisfied. Real-time access to transaction history, fast updates, and error-free records ensure trust and reliability, fostering
                      </p>
                    </div>
                  </li>

                  <li className="grid mb-10 items-start grid-flow-col justify-start">
                    <div className="w-12 h-12 bg-yellow-500 text-white rounded-full flex items-center justify-center mr-5">
                      <IconBrandSpeedtest color="#000" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-yellow-500">
                        Fast and Reliable 24/7 Data Access
                      </h3>
                      <p className="text-md text-white">
                        You can access your data from anywhere, at any time,
                        without any downtime.
                      </p>
                    </div>
                  </li>


                  <li className="grid mb-10 items-start grid-flow-col justify-start">
                    <div className="w-12 h-12 bg-yellow-500 text-white rounded-full flex items-center justify-center mr-5">
                      <IconRocket color="#000" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-yellow-500">
                       Increase Transactions
                      </h3>
                      <p className="text-md text-white">
                        Our mobile app equips agents to process transactions
                        more quickly, leading to an increased number of
                        transactions.
                      </p>
                    </div>
                  </li>

                  <li className="grid mb-10 items-start grid-flow-col justify-start">
                    <div className="w-12 h-12 bg-yellow-500 text-white rounded-full flex items-center justify-center mr-5">
                      <IconTrack color="#000" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-yellow-500">
                        Better Accountability
                      </h3>
                      <p className="text-md text-white">
                        With a digital record-keeping system, every transaction
                        can be tracked, making it easier to hold agents
                        accountable.
                      </p>
                    </div>
                  </li>

                  <li className="grid mb-10 items-start grid-flow-col justify-start">
                    <div className="w-12 h-12 bg-yellow-500 text-white rounded-full flex items-center justify-center mr-5">
                      <IconLock color="#000" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-yellow-500">
                        Data Security
                      </h3>
                      <p className="text-md text-white">
                        With our cloud-based infrastructure, we ensure the
                        safety of your financial records, making it easy to
                        retrieve past transactions.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </article>
          </div>
        </section>

        <section className="py-32 bg-white text-black container mx-auto px-3">
          <h4 className="font-bold text-center mb-20 text-4xl  Hero ">
            How We Empower Your Savings & Loans Business
          </h4>

          <div className="container mx-auto flex flex-col lg:flex-row gap-10 px-4">
            {/* Step 1: Secure Business Management */}
            <div className="flex flex-col items-center lg:w-1/4">
              <div className="w-24 h-24 bg-yellow-500 text-white rounded-full flex items-center justify-center mb-7">
                <IconLockAccess color="#000" size={40} />
              </div>
              <h5 className="text-2xl font-semibold text-black Hero">
                Secure Operations
              </h5>
              <p className="text-center text-gray-600 text-base mt-2 Hero">
                A secure platform ensures that businesses can manage customer
                savings and transactions efficiently.
              </p>
            </div>

            {/* Step 2: Real-Time Transaction Recording */}
            <div className="flex flex-col items-center lg:w-1/4">
              <div className="w-24 h-24 bg-yellow-500 text-white rounded-full flex items-center justify-center mb-7">
                <IconClock color="#000" size={40} />
              </div>
              <h5 className="text-2xl font-semibold Hero">
                Real-Time Recording
              </h5>
              <p className="text-center text-gray-600 text-base  mt-2 Hero">
                Field agents can record transactions in real time, reducing
                delays and improving accuracy.
              </p>
            </div>

            {/* Step 3: Seamless Coordination */}
            <div className="flex flex-col items-center lg:w-1/4">
              <div className="w-24 h-24 bg-yellow-500 text-white rounded-full flex items-center justify-center mb-7">
                <IconUsers color="#000" size={40} />
              </div>
              <h5 className="text-2xl font-semibold Hero">
                Seamless Coordination
              </h5>
              <p className="text-center text-gray-600 text-base mt-2 Hero">
                Businesses and field agents can work together effectively using
                DigiCollect’s intuitive tools.
              </p>
            </div>

            {/* Step 4: Mobile Accessibility */}
            <div className="flex flex-col items-center lg:w-1/4">
              <div className="w-24 h-24 bg-yellow-500 text-white rounded-full flex items-center justify-center mb-7">
                <IconDeviceMobile color="#000" size={40} />
              </div>
              <h5 className="text-2xl font-semibold Hero">
                Mobile Accessibility
              </h5>
              <p className="text-center text-gray-600 text-base mt-2 Hero">
                Field agents can access DigiCollect from anywhere, enabling
                on-the-go deposits and withdrawals.
              </p>
            </div>
          </div>
        </section>

        {/* end benefits section */}


        {/**What Our client say */}
        <section className="bg-gray-100" style={{ padding: 20}}>
  <div className="container mx-auto px-6 text-center flex justify-center items-center" style={{ minHeight: 'calc(100vh - 50px)' }}>
    <div className="text-center">
      <h2 className="text-3xl font-semibold text-gray-800 mb-20 Hero">What Our Clients Say</h2>
      <div className="flex flex-col sm:flex-row justify-center items-center space-x-8">
        <div className="w-full sm:w-1/3 mb-8 sm:mb-0 text-center">
          <blockquote className="text-lg text-gray-600 italic">
            "DigiCollect made managing our micro-finance operations so much easier and faster! The platform is seamless and user-friendly."
          </blockquote>
          <p className="font-bold text-gray-800">Kingsley Abroquah</p>
          <p className="text-sm text-gray-500">CEO, Appointed Time</p>
        </div>

        <div className="w-full sm:w-1/3 mb-8 sm:mb-0 text-center">
          <blockquote className="text-lg text-gray-600 italic">
            "Thanks to DigiCollect, we've been able to serve more customers efficiently and securely. The app is a game-changer!"
          </blockquote>
          <p className="font-bold text-gray-800">Philip Asiedu</p>
          <p className="text-sm text-gray-500">Founder, Homecash Susu Enterprise</p>
        </div>

        <div className="w-full sm:w-1/3 text-center">
          <blockquote className="text-lg text-gray-600 italic">
            "The best decision we made was adopting DigiCollect for our business. It has streamlined our operations like never before!"
          </blockquote>
          <p className="font-bold text-gray-800">Michael Johnson</p>
          <p className="text-sm text-gray-500">CEO, Enkasa Savings & Loans</p>
        </div>
      </div>
    </div>
  </div>
</section>








        {/* platform section */}
        <section className="py-20 ">
          <article className="container mx-auto flex flex-col lg:flex-row items-center px-3">
            <div className="w-full lg:w-6/12 ">
              <h2 className="text-4xl font-bold mb-10 Hero text-black">
                Download The DigiCollect App
              </h2>

              <p className="text-lg text-black mb-0 ">
                Android mobile app for field agents available on portal
              </p>

              <div>
                <div className="w-full flex gap-1">
                  {/* <Link to="/register" className="flex-0.5">
                  <img
                    src={AppleStoreImage}
                    alt=""
                    height={100}
                    width={140}
                    loading="lazy"
                  />
                </Link>
                */}
                  {/* <Link to="#" className="flex-1">
                  <img
                    src={GooglePlayImage}
                    alt=""
                    width={'35%'}
                    loading="lazy"
                  />
                </Link>  */}
                </div>
              </div>
            </div>

            <div className="w-full lg:w-6/12 md:w-6/12 mb-20 ">
              {/* <a href="https://www.freepik.com/free-photo/african-american-woman-using-smartphone-against-blue-background_21129920.htm#fromView=search&page=1&position=50&uuid=225f771f-7036-4621-8354-e3bfcfe324fc">Image by fabrikasimf on Freepik</a> */}
              <img src={manOnPhone} alt="" loading="lazy" />
            </div>
          </article>
        </section>
        {/* end platform section */}
      </>
    </LandingLayout>
  );
};

export default LandingHome;
