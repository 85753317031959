import { jsPDF } from "jspdf";
import "jspdf-autotable";
import notify from "./notify";
import { customerProp } from "../views/customers";
import { formatCurrency } from "./currencyFormat";
import { formatDate } from "./formatDate";


const months = [
  "January", "February", "March", "April", "May", "June", 
  "July", "August", "September", "October", "November", "December"
];
  
const downloadCustomerStatement = (
  customer: customerProp, 
  transactionData: any, 
  orgName: string, 
  period: "thisMonth" | "all"
) => {
  // Filter data based on the selected period
  const filteredData = transactionData.filter((data: any) => {
    const isMatchingCustomer = 
      data.accountNumber === customer.accountNumber &&
      data.customer === customer.fullName;

    if (period === "thisMonth") {
      const createdAtDate = new Date(data.date);
      const currentDate = new Date();
      return (
        isMatchingCustomer &&
        createdAtDate.getMonth() === currentDate.getMonth() &&
        createdAtDate.getFullYear() === currentDate.getFullYear()
      );
    }

    return isMatchingCustomer; // For "all" period, match customer only
  });

  if (filteredData.length === 0) {
    notify("Statement not available");
    return;
  }

  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;

  // Center the orgName
  doc.setFontSize(16);
  const orgNameWidth = doc.getTextWidth(orgName);
  doc.text(`${orgName}`, (pageWidth - orgNameWidth) / 2, 20);

  // Center the Transaction Statement text
  doc.setFontSize(12);
  const statementText = `Transaction Statement for ${period === 'thisMonth' ? months[new Date().getMonth()]:':'}`;
  const statementTextWidth = doc.getTextWidth(statementText);
  doc.text(statementText, (pageWidth - statementTextWidth) / 2, 30);

  const customerName = `${customer.fullName}`;
    const NameWidth = doc.getTextWidth(customerName)
  doc.text(customerName, (pageWidth - NameWidth) / 2, 40);

  // Center the Account Balance text
  const accountBalanceText = `Account Balance ${formatCurrency(parseFloat(customer.accountBalance), 'GHS')}`;
  const accountBalanceTextWidth = doc.getTextWidth(accountBalanceText);
  doc.text(accountBalanceText, (pageWidth - accountBalanceTextWidth) / 2, 50);


  // Center the Account Number text
  doc.setFontSize(10);
  const accountNumberText = `Account Number: ${customer.accountNumber}`;
  const accountNumberWidth = doc.getTextWidth(accountNumberText);
  doc.text(accountNumberText, (pageWidth - accountNumberWidth) / 2, 56);

  const marginBottom = 10;
  const nextYPosition = 70 + marginBottom;

  // Set up table headers
  const headers = ["ID", "Transaction Date", "Accounting Date", "Transaction Type", "Amount", "Field Agent"];
  const rows = filteredData.map((data: any) => [
    data.transId,
    formatDate(data.createdAt),
    formatDate(data.updatedAt),
    data.transactionType,
    // data.accountNumber,
    // data.customer,
    formatCurrency(data.amount, 'GHS'),
    data.agentName
  ]);

  doc.autoTable({
    head: [headers],
    body: rows,
    startY: nextYPosition,
    margin: { top: 5, left: 5, bottom: 5, right: 5 },
  });

  const pdfDataUrl = doc.output("bloburl");

  window.open(pdfDataUrl);
};


  export {
    downloadCustomerStatement
  }