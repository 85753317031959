import React, { useState } from 'react'
import { Button, Paper, Text, TextInput, Title } from '@mantine/core';
import { formatCurrency } from '../utils/currencyFormat';
import { IconFileExcel, IconFilter, IconRotate } from '@tabler/icons-react';
import { handleExport } from '../services/core-api';
import TransactionsTable from '../components/TransactionsTable';
import { useLoanDataContext } from '../context/LoanContext';
import { formatDate } from 'date-fns';


export default function LoanTransactions() {
  const [isloading, setIsloading]= useState(false)
  const {loanTransactions, setLoanTransactions, totalPaid} = useLoanDataContext()
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [transactions, setTransactions] = useState(loanTransactions)

  const filterData = () => {
    let filtered = transactions;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);       
      filtered = filtered.filter(trans => {
        const transactionDate = new Date(trans.createdAt.seconds * 1000);
        return transactionDate >= start && transactionDate <= end;
      });
    }

    setTransactions(filtered);
  };
  
    
      const downloadData = ()=> {
        setIsloading(true)
        handleExport(loanTransactions? loanTransactions : []).then(()=> {
        setIsloading(false)
        }).catch((err)=> {
          console.log(err)
        setIsloading(false)
        })
      }

  return (
    <>
        <Paper
      withBorder
      style={{borderColor: '#dedede'}}
      p={20}
      >
        <div  style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: 'wrap',
        }}>
           
    
         <div>
         <Title mb={5} order={5} style={{ fontWeight: 'normaL'}}> Total Paid Loan</Title>
         <Title mb={10} order={2}> {formatCurrency(totalPaid, 'GHS')}</Title>
         </div>

         <div>
         <Title mb={5} order={5} style={{ fontWeight: 'normaL'}}> Total Loans</Title>
         <Title mb={10} order={2}> {loanTransactions.length}</Title>
         </div>


        </div>
     

     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
    <div style={{ display: 'flex', width: '70%', flexWrap: 'wrap', alignItems:'center'}}>
    <Button loading={isloading}  variant="outline" disabled={isloading} mr={10} color="#000" onClick={downloadData}> 
        <IconFileExcel /> &nbsp; Export to XLSx
        </Button>
        
      
        <TextInput
         mr={10}
         name='State date'
          width='20%'
          type='date'
          value={startDate}
          onChange={(e:any) => setStartDate(e.target.value)}
          placeholder='Start Date'
        />
          <Text fw={'bold'}>To</Text>
        <TextInput
        ml={10}
         name='End date'
         width='20%'
          type='date'
          value={endDate}
          onChange={(e: any) => setEndDate(e.target.value)}
          placeholder='End Date'
        />

<Button leftSection={<IconFilter size={20}/>} variant='filled' color='#000' onClick={filterData} style={{ marginInline: 10 }}>
          Filter Date
        </Button>
        </div>

        <Button leftSection={<IconRotate size={20}/>} variant='filled' color='#000' onClick={()=> {
          setStartDate('')
          setEndDate('')
          setTransactions(loanTransactions)
        }} style={{ marginInline: 10 }}>
          Refresh
        </Button>
      </div>

      <div className='tableOnMobile'>
    <TransactionsTable showPageTotalAmount={false} data={transactions} dataType='loan'/>
    </div>
    </Paper>
    </>
  )
}


