import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this import according to your project structure

interface Transaction {
    id: string;
    transId: string;
    customer: string;
    amount: string;
    accountNumber: string;
    date: string;
    agentName: string;
    agentID: string;
    transactionsMethod: string;
    agentEmail: string;
    phone: string;
    createdAt: any;
    approved: boolean;
    transactionType: string;
}

// Define the context type
interface TransactionDataContextProps {
    depositTransactions: Transaction[]; // List of deposit transactions
    withdrawalTransactions: Transaction[]; // List of withdrawal transactions
    setDTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>;
    setWTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>;
    // loading: boolean; // Loading state
    // refreshTransactions: () => void; // Function to refresh transactions data
}

// Create the context
const TransactionDataContext = createContext<TransactionDataContextProps | null>(null);

// Provider component
export const TransactionDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [depositTransactions, setDepositTransactions] = useState<Transaction[]>([]);
    const [withdrawalTransactions, setWithdrawalTransactions] = useState<Transaction[]>([]);
    const user = auth.currentUser;

    useEffect(() => {
        if (user) {
            const fetchTransactions = async () => {
                const idTokenResult = await user.getIdTokenResult();
                const claims = idTokenResult.claims;

                if (claims.admin) {

                    const depositQuery = query(
                        collection(db, 'transactions'),
                        where('orgId', '==', claims.orgId),
                        where('transactionType', '==', 'Deposit'),
                        orderBy('createdAt', 'desc')
                    );

                    const withdrawalQuery = query(
                        collection(db, 'transactions'),
                        where('orgId', '==', claims.orgId),
                        where('transactionType', '==', 'Withdrawal'),
                        orderBy('createdAt', 'desc')
                    );

                    // Real-time listener for deposit transactions
                    const unsubscribeDeposits = onSnapshot(depositQuery, (snapshot) => {
                        const depositData = snapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        })) as Transaction[];
                        setDepositTransactions(depositData);
                    });

                    // Real-time listener for withdrawal transactions
                    const unsubscribeWithdrawals = onSnapshot(withdrawalQuery, (snapshot) => {
                        const withdrawalData = snapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        })) as Transaction[];
                        setWithdrawalTransactions(withdrawalData);
                    });

                    // Cleanup subscriptions on unmount
                    return () => {
                        unsubscribeDeposits();
                        unsubscribeWithdrawals();
                    };
                }
            };

            fetchTransactions().catch((error) => {
                console.error('Error setting up real-time listeners:', error);
            });
        } else {
            setDepositTransactions([]);
            setWithdrawalTransactions([]);
        }

        return () => {
            setDepositTransactions([]);
            setWithdrawalTransactions([]);
        };
    }, []);

    return (
        <TransactionDataContext.Provider
            value={{
                depositTransactions,
                withdrawalTransactions,
                setDTransactions: setDepositTransactions,
                setWTransactions: setWithdrawalTransactions,
                // loading,
                // refreshTransactions: () => {}, 
            }}
        >
            {children}
        </TransactionDataContext.Provider>
    );
};

// Custom hook to use the TransactionDataContext
export const useTransactionDataContext = () => {
    const context = useContext(TransactionDataContext);
    if (!context) {
        throw new Error('useTransactionDataContext must be used within a TransactionDataProvider');
    }
    return context;
};
