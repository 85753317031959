import CryptoJS from 'crypto-js';
import { getConfig } from '../services/core-api';

const encryptValue = (value: string, SECRET_KEY: string): string => {
  return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
};

const decryptValue = (ciphertext: string, SECRET_KEY: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};


const isBase64 = (value: string): boolean => /^[A-Za-z0-9+/]+={0,2}$/.test(value);

const isEncrypted = async (value: string): Promise<boolean> => {
  if (typeof value !== 'string' || value.length < 16) {
    return false;
  }

  if (!isBase64(value)) {
    return false; 
  }

  try {
    const { encryptKey } = await getConfig(); 
    const decrypted = decryptValue(value, encryptKey);

    return !!decrypted && decrypted !== value;
  } catch (error) {
    console.error('Error in isEncrypted:', error);
    return false;
  }
};

export {
  encryptValue,
  decryptValue,
  isEncrypted,
};
