import { Table, Pagination, Badge, Text, Title, Modal, TextInput, Button, Loader, Select } from '@mantine/core';
import React, { useState } from 'react';
import { formatCurrency } from '../utils/currencyFormat';
import { formatDate } from '../utils/formatDate';
import { IconCalendar, IconEdit, IconInfoSmall, IconPlus, IconSearch } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { editTransaction } from '../services/core-api';
import { useUser } from '../context/UserContext';
import notify from '../utils/notify';
import { formatDateWithCurrentTime } from '../utils/formatDateWithTIme';
import { useAgentsContext } from '../context/agentsContext';

interface tableType {
  data: any;
  dataType: string;
  showPageTotalAmount: boolean
}

const TransactionsTable = ({ data, dataType, showPageTotalAmount }: tableType) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const [opened, { open, close }] = useDisclosure(false);
  const { user } = useUser();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [newDate, setNewDate] = useState('');
  const [openRows, setOpenRows] = useState<Set<string>>(new Set());
  
  // State for account number filter
  const [search, setSearch] = useState('');
  const [agentSearch, setAgentSearch] = useState<string | null>('');
  const {agents} = useAgentsContext()


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtered data based on account number
  const filteredData = data.filter((trans: Transaction) =>
    trans.accountNumber.toLowerCase().includes(search.toLowerCase()) || trans.customer.toLowerCase().includes(search.toLowerCase())
  );

    // Filtered data based on account number
    const filteredAgent = data.filter((trans: Transaction) =>
      trans.agentName.toLowerCase().includes(agentSearch? agentSearch.toLowerCase() : '')
    );


  const rows = search !== '' ? filteredData.slice(startIndex, endIndex) : filteredAgent.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  interface Transaction {
    id: string;
    transId: string;
    customer: string;
    amount: string;
    amountPaid: number
    balance: string;
    accountNumber: string;
    date: string;
    agentName: string;
    agentID: string;
    transactionsMethod: string;
    agentEmail: string;
    phone: string;
    createdAt: any;
    approved: boolean;
    transactionType: string;
    updatedAt?: string;
    updatedBy?: string;
    approvedBy?: string;
  }

  const totalAmount = rows.reduce((sum: number, trans: Transaction) => sum + parseFloat(trans.amount), 0);

  const handleEditClick = (transaction: Transaction) => {
    setSelectedTransaction(transaction);
    setNewDate(formatDate(transaction.createdAt));
    open();
  };

  const handleUpdate = async () => {
    setUpdateLoading(true);
    const email = user?.email;
    if (newDate === '') {
      notify('Date field cannot be empty');
      setUpdateLoading(false);
      return;
    }
    try {
      if (selectedTransaction) {
        const response = await editTransaction(email || '', selectedTransaction.id, formatDateWithCurrentTime(newDate));
        notify(response);
        setUpdateLoading(false);
        close();
      }
    } catch (err) {
      console.error(err);
      setUpdateLoading(false);
    }
  };

  const toggleRow = (id: string) => {
    setOpenRows((prev) => {
      const newRows = new Set(prev);
      if (newRows.has(id)) {
        newRows.delete(id);
      } else {
        newRows.add(id);
      }
      return newRows;
    });
  };


  return (
    <>
      {/* Modal for editing transaction date */}
      <Modal opened={opened} onClose={close} title={<Title order={5}>Edit Transaction Date</Title>} centered>
        {/* Modal content */}
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <TextInput
            disabled
            w={'45%'}
            type="text"
            label="Customer Name"
            mb={10}
            value={selectedTransaction?.customer}
            onChange={() => {}}
          />
          <TextInput
            disabled
            w={'45%'}
            type="text"
            label="Account Number"
            mb={10}
            value={selectedTransaction?.accountNumber}
            onChange={() => {}}
          />
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <TextInput
            disabled
            w={'45%'}
            type="text"
            label="Current Date"
            mb={10}
            rightSection={<IconCalendar size={16} />}
            value={formatDate(selectedTransaction?.createdAt)}
            onChange={() => {}}
          />
          <TextInput
            w={'45%'}
            type="date"
            label="New Date"
            mb={10}
            value={newDate}
            onChange={(e) => setNewDate(e.currentTarget.value)}
          />
        </div>
        <Button mt={20} disabled={updateLoading} color={'#000'} w={'100%'} onClick={handleUpdate}>
          {updateLoading ? <Loader size={16} color='#000' /> : 'Update'}
        </Button>
      </Modal>

              
      <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "100%" }}>
           <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between', width: '100%'}}>
         <div style={{ display: 'flex'}}>
         <Select searchable placeholder='Select agent name' label='Select Agent' mr={10} data={agents.map((agent)=> agent.displayName)} value={agentSearch} onChange={setAgentSearch} />
           <TextInput
           label='Search customer'
               leftSection={<IconSearch size={16} />}
                 miw={350}
                  placeholder="Search by customer name or account number"
                  value={search}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                  size="sm"
                />
         </div>
           </div>
        <Table stickyHeader stripedColor='#f2f2f2' highlightOnHover highlightOnHoverColor='#f4f3ef' striped mt={30}>
          <Table.Thead style={{ backgroundColor: '#fafafa' }}>
            <Table.Tr>
              <Table.Th><IconInfoSmall /></Table.Th>
              <Table.Th style={{ fontWeight: 'bold' }}>Transaction Date</Table.Th>
              <Table.Th style={{ fontWeight: 'bold' }}>Accounting Date</Table.Th>
              <Table.Th style={{ fontWeight: 'bold' }}>Transaction ID</Table.Th>
              <Table.Th style={{ fontWeight: 'bold' }}>Account Number </Table.Th>
              <Table.Th style={{ fontWeight: 'bold' }}>Customer</Table.Th>
              <Table.Th style={{ fontWeight: 'bold' }}>Amount</Table.Th>
              <Table.Th style={{ fontWeight: 'bold' }}>Agent</Table.Th>
              {dataType !== 'loan'&& <Table.Th style={{ fontWeight: 'bold' }}>Edited By</Table.Th>}
              {dataType === 'withdrawal' && <Table.Th style={{ fontWeight: 'bold' }}>Status</Table.Th>}
              {dataType !== 'loan' && <Table.Th style={{ fontWeight: 'bold' }}>Action</Table.Th>}
           
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rows.map((trans: Transaction) => (
              <React.Fragment key={trans.id}>
                <Table.Tr 
                  // style={{ backgroundColor: trans.updatedAt ? '#fff9ee' : '', cursor: 'pointer' }}
                  onClick={() => toggleRow(trans.id)}
                >
                  <Table.Td style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <IconPlus size={16} />
                  </Table.Td>
                  <Table.Td style={{ fontWeight: 'bold' }}>{formatDate(trans.createdAt)}</Table.Td>
                  <Table.Td style={{ fontWeight: 'bold' }}>{trans.updatedAt ? formatDate(trans.updatedAt) : formatDate(trans.createdAt)}</Table.Td>
                  <Table.Td>{trans.transId}</Table.Td>
                  <Table.Td>{trans.accountNumber}</Table.Td>
                  <Table.Td>{trans.customer}</Table.Td>
                   {trans.amountPaid ? <Table.Td style={{ fontWeight: 'bold', color: 'green' }}>{formatCurrency(trans.amountPaid, 'GHS')}</Table.Td> : 
                   <Table.Td style={{ fontWeight: 'bold', color: 'green' }}>{formatCurrency(parseFloat(trans.amount), 'GHS')}</Table.Td>}
                  <Table.Td>{trans.agentName}</Table.Td>
                  <Table.Td style={{ fontWeight: 'bold' }}>{trans.updatedBy ? trans.updatedBy : ''}</Table.Td>
                  {dataType === 'withdrawal' && (
                    <Table.Td>
                      {trans.approved ? (
                        <Badge size='sm' color='green'>Approved</Badge>
                      ) : (
                        <Badge size='sm' color='orange'>Pending</Badge>
                      )}
                    </Table.Td>
                  )}
                  {dataType !== 'loan' &&    <Table.Td>
                    <IconEdit size={18} onClick={() => handleEditClick(trans)} style={{ cursor: 'pointer' }} />
                  </Table.Td>}
               
                </Table.Tr>
                {/* Collapsible content */}
                {openRows.has(trans.id) && (
                  <Table.Tr>
                    <Table.Td colSpan={14} p={20} style={{ backgroundColor: '#fff' }}>
                      <Title order={4} mb={10}>Details</Title>
                      <div style={{ display: "flex", textAlign: "left" }}>
                        <p><strong>Phone Number:</strong></p>
                        <p style={{ marginLeft: 5 }}>{trans.phone}</p>
                      </div>
                      {/* <div style={{ display: "flex", textAlign: "left" }}>
                        <p><strong>Agent Name:</strong></p>
                        <p style={{ marginLeft: 5 }}>{trans.agentName}</p>
                      </div> */}
                      <div style={{ display: "flex", textAlign: "left" }}>
                        <p><strong>Agent ID:</strong></p>
                        <p style={{ marginLeft: 5 }}>{trans.agentID}</p>
                      </div>
                      <div style={{ display: "flex", textAlign: "left" }}>
                        <p><strong>Agent Email:</strong></p>
                        <p style={{ marginLeft: 5 }}>{trans.agentEmail}</p>
                      </div>
                      {trans.approvedBy && (
                        <div style={{ display: "flex", textAlign: "left" }}>
                          <p><strong>Approved By:</strong></p>
                          <p style={{ marginLeft: 5 }}>{trans.approvedBy || 'Not available'}</p>
                        </div>
                      )}
                      <div style={{ display: "flex", textAlign: "left" }}>
                        <p><strong>Transaction Method:</strong></p>
                        <p style={{ marginLeft: 5 }}>{trans.transactionsMethod || 'Cash'}</p>
                      </div>
                    </Table.Td>
                  </Table.Tr>
                )}
              </React.Fragment>
            ))}
            {showPageTotalAmount && <Table.Tr style={{ backgroundColor: '#000', color: '#fff' }}>
              <Table.Td colSpan={4} style={{ textAlign: 'right', fontWeight: 'normal', fontSize: 18 }}>
                Page {currentPage} Total Amount:
              </Table.Td>
              <Table.Td style={{ fontWeight: 'bold', fontSize: 18, color: '#fff' }}>{formatCurrency(totalAmount, 'GHS') }</Table.Td>
              <Table.Td colSpan={6}></Table.Td>
            </Table.Tr>}
          </Table.Tbody>
        </Table>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, marginBottom: 20 }}>
        <small style={{ fontWeight: 'bold' }}>Page {currentPage} of {totalPages}</small>
        <Pagination
          color='#000'
          size={'sm'}
          total={totalPages}
          value={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default TransactionsTable;
