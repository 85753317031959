
// function formatCurrency(amount: number, currency: string, locale: string = `en-${currency.slice(0, 2)}`): string {
//     return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount);
//   }

//   export {
//     formatCurrency
//   }


  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount);
  };

  export {
    formatCurrency
  }