import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase'; // Ensure this path is correct
import { collection, getDocs, query, where, onSnapshot, orderBy,  } from 'firebase/firestore';

// Define the shape of the loan data
interface LoanProp {
    id:string
    date: string
    createdAt: any
    customer: string
    accountNumber: string
    phone: string
    accountBalance: number
    loanAmount: number
    outstandingBalance: number
    tenure: string
    interestRate: string
    interestRateAmount: number
    monthlyInstallment: string
    totalPayment: string
   agentName: string 
   transactionDate: string
   agentId: string
}

// Define the context type
interface LoanDataContextProps {
  loans: LoanProp[];
  setLoans: React.Dispatch<React.SetStateAction<LoanProp[]>>;
  loading: boolean;
  totalLoanAmount: number
  totalOutStandingLoanAmount: number
  totalPaid: number
  loanTransactions: LoanProp[],
  setLoanTransactions: React.Dispatch<React.SetStateAction<LoanProp[]>>;
  refreshLoans: (orgId: string) => void;
}

// Create the context
const LoanDataContext = createContext<LoanDataContextProps | null>(null);

// Provider component
export const LoanDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loans, setLoans] = useState<LoanProp[]>([]);
  const [loanTransactions, setLoanTransactions] = useState<LoanProp[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalLoanAmount, setTotalLoanAmount] = useState<number>(0);
  const [totalOutStandingLoanAmount, setTotalOutStandingLoanAmount] = useState<number>(0);
  const [totalPaid, setTotalPaid] = useState<number>(0);

  // Fetch loan data
  const getLoanData = (orgId: string) => {
    setLoading(true);
    try {
      const loansQuery = query(collection(db, 'loans'), where('orgId', '==', orgId), orderBy("createdAt", "desc"));
      
      // Real-time listener with onSnapshot
      const unsubscribe = onSnapshot(loansQuery, (querySnapshot) => {
        if (querySnapshot.empty) {
          console.log('No loan collections found for the specified orgID');
          setLoans([]);
          return;
        }
  
        const loanData: LoanProp[] = querySnapshot.docs.map((doc) => {
          const data = doc.data() as LoanProp;
          return {
            ...data,
            id: doc.id,
          };
        });
  
        setLoans(loanData);
  
        // Calculate total loan and outstanding balances
        const totalAmount = loanData.reduce((total, loan) => total + loan.loanAmount, 0);
        const outStandingAmount = loanData.reduce((total, loan) => total + loan.outstandingBalance, 0);
        const totalLoanPaid =  totalAmount - outStandingAmount
        setTotalPaid(totalLoanPaid)
        setTotalLoanAmount(totalAmount);
        setTotalOutStandingLoanAmount(outStandingAmount);
      });
  
      // Optional: Return unsubscribe function if you want to stop listening to changes later
      return unsubscribe;
    } catch (error: any) {
      console.error('Error fetching loan collections:', error.message);
    } finally {
      setLoading(false);
    }
  };



  const getLoanTransactions = (orgId: string) => {
    setLoading(true);
    try {
      const loansQuery = query(collection(db, 'loanPaymentTransactions'), where('orgId', '==', orgId), orderBy("createdAt", "desc"));
      
      // Real-time listener with onSnapshot
      const unsubscribe = onSnapshot(loansQuery, (querySnapshot) => {
        if (querySnapshot.empty) {
          console.log('No loan transactions collections found for the specified orgID');
          setLoanTransactions([]);
          return;
        }
  
        const loanTransactionsData: LoanProp[] = querySnapshot.docs.map((doc) => {
          const data = doc.data() as LoanProp;
          return {
            ...data,
            id: doc.id,
          };
        });
  
        setLoanTransactions(loanTransactionsData);
        // Calculate total loan and outstanding balances
        // const totalAmount = loanTransactionsData.reduce((total, loan) => total + loan.loanAmount, 0);
      });
  
      // Optional: Return unsubscribe function if you want to stop listening to changes later
      return unsubscribe;
    } catch (error: any) {
      console.error('Error fetching loan transactions collections:', error.message);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchLoans = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const token = await user.getIdTokenResult();
        const orgID = token.claims.orgId;

        if (typeof orgID === 'string') {
          await getLoanData(orgID); // Pass orgID as a string
        } else {
          console.warn('OrgID is not a string or is missing in user claims.');
        }
      } catch (error) {
        console.error('Error fetching user token:', (error as Error).message);
      }
    } else {
      console.warn('User not authenticated.');
    }
  };

  const fetchLoanTransactions = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const token = await user.getIdTokenResult();
        const orgID = token.claims.orgId;

        if (typeof orgID === 'string') {
          await getLoanTransactions(orgID);
        } else {
          console.warn('OrgID is not a string or is missing in user claims.');
        }
      } catch (error) {
        console.error('Error fetching user token:', (error as Error).message);
      }
    } else {
      console.warn('User not authenticated.');
    }
  };

  useEffect(() => {
    fetchLoanTransactions()
    fetchLoans();
  }, []);
  

  return (
    <LoanDataContext.Provider
      value={{
        loans,
        setLoans,
        totalLoanAmount,
        totalOutStandingLoanAmount,
        totalPaid,
        loanTransactions,
        setLoanTransactions,
        loading,
        refreshLoans: getLoanData,
      }}
    >
      {children}
    </LoanDataContext.Provider>
  );
};

export const useLoanDataContext = () => {
  const context = useContext(LoanDataContext);
  if (!context) {
    throw new Error('useLoanDataContext must be used within a LoanDataProvider');
  }
  return context;
};
