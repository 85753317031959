import React, { useState } from "react";
import LandingLayout from "../layouts/landing";
import LandingNav from "../components/LandingNav";
import InputText from "../components/InputText";
import { Mail, Phone } from "lucide-react";
import TextArea from "antd/es/input/TextArea";
import { Link } from "react-router-dom";
import {
  IconBrandInstagram,
  IconBrandWhatsapp,
  IconBrandX,
} from "@tabler/icons-react";
import FAQ from "../components/Faq";
import { contactUs } from "../services/core-api";
import notify from "../utils/notify";
import { validateFields } from "../utils/validateField";
import { Button } from "@mantine/core";

const LandingContactUs = () => {
 const [loading, setLoading]= useState(false)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  // Handle input changes
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
  
    // Validate fields
    const validationError = validateFields(formData);
    if (validationError) {
      notify(validationError); 
      return;
    }
  
    try {
      setLoading(true)
      const response = await contactUs(formData);
      if (response) {
        notify(response.message);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      }
    } catch (err: any) {
      notify(err.message);
    }finally{
      setLoading(false)
    }
  };
  

  return (
    <LandingLayout>
      <LandingNav inView={true} />
      <main>
        {/* contact info & form */}
        <section className=" bg-gray-100">
          <div className="container mx-auto py-28 px-3 flex gap-10 flex-col lg:flex-row">
            <div className="Hero lg:w-7/12 py-6">
              <h1 className="text-4xl font-bold text-center text-black Hero">
                Contact Us
              </h1>
              <p className="text-gray-500 text-lg mt-5 Hero font-medium">
                Email, call or complete the form below to get in touch with us.
              </p>

              <address>
                <a
                  href="mailto:info@digicollect.app"
                  className="text-lg text-gray-500 mt-5 Hero font-medium inline-block"
                >
                  info@digicollect.app
                </a>
                <br />
                <div>
                  <a
                    href="tel:+233-020-422-5765"
                    className="text-lg text-gray-500 mt-5 Hero font-medium inline-block"
                  >
                    +233 020 422 5765
                  </a>{" "}
                  |{" "}
                  <a
                    href="tel:+233-025-657-8084"
                    className="text-lg text-gray-500 mt-5 Hero font-medium inline-block"
                  >
                    {" "}
                    +233 025 657 8084
                  </a>
                </div>
              </address>

              {/* socials */}
              <div className="mt-20 grid lg:grid-cols-2 gap-5">
                <Link
                  to="https://wa.me/233204073367"
                  className="text-lg Hero font-medium block p-6 bg-white rounded-lg shadow-md text-center transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-xl hover:bg-green-400 hover:text-white"
                >
                  <IconBrandWhatsapp size={28} />{" "}
                  <div className="Hero">Chat with us on WhatsApp</div>
                </Link>
                <Link
                  to="/"
                  className="text-lg Hero font-medium block p-6  bg-white rounded-lg shadow-md text-center transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-xl hover:bg-black hover:text-white"
                >
                  <IconBrandX size={24} />{" "}
                  <div className="Hero">Chat with us on X</div>
                </Link>
                <Link
                  to="/"
                  className="text-lg Hero font-medium block p-6  bg-white rounded-lg shadow-md text-center transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-xl hover:bg-[#E4405F] hover:text-white"
                >
                  <IconBrandInstagram size={28} />{" "}
                  <div className="Hero">Chat with us on Instagram</div>
                </Link>
              </div>
            </div>

            <form className="lg:w-5/12 Hero p-6  bg-white rounded-lg shadow-md text-center">
              <legend className="text-2xl text-black font-bold mb-10 Hero">
                Get In Touch
              </legend>
              <div className="flex gap-x-5">
                <div className="flex-1">
                  <InputText
                    placeholder={"First Name"}
                    type={"text"}
                    value={formData.firstName}
                    name={"firstName"}
                    onChange={handleChange}
                    width={""}
                  />
                </div>
                <div className="flex-1">
                  <InputText
                    placeholder={"Last Name"}
                    type={"text"}
                    value={formData.lastName}
                    name={"lastName"}
                    onChange={handleChange}
                    width={""}
                  />
                </div>
              </div>
              <div className="mt-5">
                <InputText
                  placeholder={"Email"}
                  type={"email"}
                  value={formData.email}
                  name={"email"}
                  onChange={handleChange}
                  width={""}
                  iconLeft={<Mail />}
                />
              </div>
              <div className="mt-5">
                <InputText
                  placeholder={"Phone Number, eg; +233 020 422 5765"}
                  type={"tel"}
                  value={formData.phone}
                  name={"phone"}
                  onChange={handleChange}
                  width={""}
                  iconLeft={<Phone />}
                />
              </div>
              <div className="mt-5">
                <TextArea value={formData.message} name="message" onChange={handleChange} placeholder="Leave a message" rows={8}></TextArea>
              </div>
           
                <Button mt={20} loaderProps={{ type: 'dots', color: '#000', size: 30 }} leftSection={<Mail />} color="#ebb305" h={60} fullWidth  loading={loading} onClick={handleSubmit} style={{ color: '#000', borderRadius: 100}} >
                 Send Message
                </Button>
              
            </form>
          </div>
        </section>
        {/*end  contact info & form */}

        {/* Frequently asked question */}
        <FAQ />
        {/* end Frequently asked question */}
      </main>
    </LandingLayout>
  );
};

export default LandingContactUs;
