import { Paper, Title, Text, Badge } from "@mantine/core";
import {
  IconArrowsExchange,
  IconMessage,
  IconUser,
  IconUsers,
  IconUsersGroup,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
// import { getAgents, getCustomers } from "../services/core-api";
import { getDoc, getDocs, collection, where, query, Timestamp } from "firebase/firestore";
import { db, auth } from "../firebase";
import { getDomainFromEmail } from "../utils/getUserDomain";
import { userType } from "../views/agents";
import '../styles/card.css'
import { useOrgInfo } from "../context/orgContext";
import { useAgentsContext } from "../context/agentsContext";
import { useDailyTransactionsCountContext } from "../context/TotalDailyTransactionCount";
import { useCustomerDataContext } from "../context/customersContext";
import { Skeleton } from "antd";

export default function Card() {
  const [transaction, setTransaction]= useState<number>(0)
  const { dailyTransactionsCount } = useDailyTransactionsCountContext()
  // const [customers, setCustomers]= useState([])
  const {agents} = useAgentsContext()
  const {customers, loading} = useCustomerDataContext()
  const { sms } = useOrgInfo()
   //low sms balance
   const lowSMSBalance = 10

  const dashboardData = [
    {
      title: "Agents",
      number: agents?.length,
      icon: <IconUsers size={24} color="#5b5b5b" />,
      indicator: "Employees",
    },
    {
      title: "SMS Balance",
      number: sms.balance,
      icon: <IconMessage size={24} color= { sms.balance <= lowSMSBalance ? 'red': "#5b5b5b"} />,
      indicator: "Total SMS Balance",
    },
    {
      title: "Customers",
      number: customers.length,
      icon: <IconUsersGroup size={24} color="#5b5b5b" />,
      indicator: "Total customers"
    },
    {
      title: "Transaction Count",
      number: dailyTransactionsCount,
      icon: <IconArrowsExchange size={24} color="#5b5b5b" />,
      indicator: "Daily Total",
      // indicator: new Date().toDateString(),
    },
  ];

  // const getCustomersData = async () => {
  //   const email = auth.currentUser?.email
  //   if(email){
  //     const customers = await getCustomers(email)
  //     setCustomers(customers)
  //   }
  //   return
  // };


 
// const read_daily_transactions = async () => {
//   const user = auth.currentUser;  
//   if (user) {
//     try {
//       const idTokenResult = await user.getIdTokenResult();
//       const claims = idTokenResult.claims;

//       if (claims.admin) {
//         const today = new Date();
//         const startOfDay = new Date(today.setHours(0, 0, 0, 0)); // Start of the current day
//         const endOfDay = new Date(today.setHours(23, 59, 59, 999)); // End of the current day
        
//         const q = query(
//           collection(db, 'transactions'),
//           where("orgId", "==", claims.orgId),
//           where("createdAt", ">=", Timestamp.fromDate(startOfDay)),
//           where("createdAt", "<=", Timestamp.fromDate(endOfDay))
//         );

//         const querySnapshot = await getDocs(q);
//         const docsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setTransaction(docsData.length);
//       } else {
//         console.log('User does not have admin privileges');
//       }
//     } catch (error) {
//       setTransaction(0);
//       console.error('Error getting custom claims or reading transactions:', error);
//     }
//   } else {
//     console.log('No user is signed in');
//   }
// };
  
  
  // const getNumberOfUsers = async () => {
  //   const userDomain = auth.currentUser?.email || ""
  //   getAgents(userDomain).then((data)=> {
  //     setAgents(`${data.length}`);
  //   }).catch(err =>  {
  //       console.log(err)
  //       setAgents('0')
  //       return []
  //   })
  
  // };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // flexWrap: "wrap",
      }}
      className="cardContainer"
    >
      {dashboardData.map((item, index) => {
        return (
            <Paper withBorder p="lg" radius="md"
             className="card"
              key={index}
              bg={"#fff"}
              h={140}
              mb={10}
              w={340}
              style={{ display: "flex", borderColor: '#dedede', justifyContent: "space-between", color:'#000', borderRadius: 8}}   
              //  boxShadow: '0 1px 10px rgba(0, 0, 0, 0.1)'
            >
              {loading ? <Skeleton active paragraph={{ rows: 1, width: '60%' }} style={{ lineHeight: '20px', margin: '10px 0' }} />
              :  <div>
                <Text style={{ fontSize: 13, fontWeight: 'bold', marginBottom: 10, color: '#5b5b5b'}}>{item.title.toUpperCase()}</Text>
                <Title style={{ color: item.title === 'SMS Balance' && sms.balance <= lowSMSBalance ? 'red' : ''}} order={2}>{item.number}</Title>     
                {item.title === 'SMS Balance' && sms.balance <= lowSMSBalance && <small style={{ color: 'red', fontSize: 10}}>SMS Low: Top up</small> }      
              </div>}
             
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-end"
                }}
              >
                {item.icon}
                <Badge variant="light" color={ item.title === 'SMS Balance' && sms.balance <= lowSMSBalance ? 'red' : "#4b4b4b"}>
                  {item.indicator}
                </Badge>
              </div>
            </Paper>
        );
      })}
    </div>
  );
}
