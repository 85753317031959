import { TextInput, Modal, Button, Title, Text } from '@mantine/core'
import React, {useState, ReactElement, ReactNode} from 'react'

interface modalProp{
    opened: boolean
    content: string | ReactNode,
    onClose: ()=> void
    proceed: ()=> void
    isLoading: boolean
    title: ReactElement
}

 function DeleteModal({opened, content, onClose, proceed, isLoading, title}:modalProp) {
  return (
    <Modal
    overlayProps={{
      backgroundOpacity: 0.55,
      blur: 3,
    }}
    opened={opened}
    onClose={onClose}
    title={title}
    centered
  >
    <Text>{content}</Text>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
    <Button variant='outline' color='#274941' m={10} onClick={onClose}>Cancel</Button>
    <Button m={10} variant='filled' color='red' onClick={proceed}>{isLoading ? 'Please wait...' : 'Yes'}  </Button>
    </div>

  </Modal>
  )
}

function ApproveModal({opened, content, onClose, proceed, isLoading, title}:modalProp) {
  return (
    <Modal
    overlayProps={{
      backgroundOpacity: 0.55,
      blur: 3,
    }}
    opened={opened}
    onClose={onClose}
    title={title}
    centered
  >
    <Text>{content}</Text>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
    <Button variant='outline' color='#274941' m={10} onClick={onClose}>Cancel</Button>
    <Button m={10} variant='filled' color='green' onClick={proceed}>{isLoading ? 'Please wait...' : 'Yes'}  </Button>
    </div>

  </Modal>
  )
}

export {
  DeleteModal,
  ApproveModal
}
