const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const isValidPhone = (phone: string) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };
  

  const validateFields = (data: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    message: string;
  }) => {
    if (!data.firstName.trim()) return "First Name is required.";
    if (!data.lastName.trim()) return "Last Name is required.";
    if (!data.email.trim()) return "Email is required.";
    if (!isValidEmail(data.email)) return "Please enter a valid email address.";
    if (!data.phone.trim()) return "Phone number is required.";
    if (!isValidPhone(data.phone)) return "Please enter a valid phone number.";
    if (!data.message.trim()) return "Message is required.";
    return null; // No errors
  };
  

  export {
    isValidEmail,
    isValidPhone,
    validateFields
  }