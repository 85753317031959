import { ChevronRightIcon } from "lucide-react";
import { useState } from "react";

interface FAQListItemProps {
  question: string;
  answer: string;
}

const FAQListItem = ({ answer, question }: FAQListItemProps) => {
  const [hidden, setHidden] = useState(false);

  const handleToggle = () => setHidden(!hidden);

  return (
    <li
      className="border-2 rounded-md cursor-pointer mb-5"
      onClick={handleToggle}
    >
      <article>
        <h3 className="flex gap-x-1 items-start font-semibold text-2xl bg-gray-200 p-3 Hero text-black">
          <ChevronRightIcon
            size={25}
            className={`text-green-500 transform transition-all ease-in-out duration-300  ${
              hidden ? "rotate-0" : "  rotate-90 "
            }`}
          />
          {question}
        </h3>

        <p
          className={`text-gray-500 mt-3 ml-7 p-3 Hero text-lg ${
            hidden ? "hidden" : ""
          }`}
        >
          {answer}
        </p>
      </article>
    </li>
  );
};

export default FAQListItem;
